import { useEffect, useState } from 'react';

// Typ pro viewport informace
interface Viewport {
    width: number;
    height: number;
    isMobajl: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isMobileOrTablet: boolean;
}

export const useViewport = (): Viewport => {
    const [viewport, setViewport] = useState<Viewport>({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobajl: window.innerWidth <= 768,
        isTablet: window.innerWidth > 768 && window.innerWidth <= 1024,
        isDesktop: window.innerWidth > 1024,
        isMobileOrTablet: window.innerWidth <= 768 || (window.innerWidth > 768 && window.innerWidth <= 1024),
    });

    useEffect(() => {
        const handleResize = () => {
            const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            const isMobajl = width <= 768;
            const isTablet = width > 768 && width <= 1024;

            setViewport({
                width,
                height,
                isMobajl,
                isTablet,
                isDesktop: width > 1024,
                isMobileOrTablet: isMobajl || isTablet,
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return viewport;
};
