import { appLanguages, paymentMethod as paymentMethodType, reservedFrontendUrls } from '@fyooga/codebook';

import { config } from './config';

// z-indices for modals
export const zIndexModal = {
    smartFormNotFoundEmailDesktop: 1051,
    reservationFormModalDesktop: 1050,
    bottomReservationButtonForEvent: 1049, // it is sometimes hidden on mobile
    // smartFormNotFoundEmailMobile: 1002,
    // reservationFormModalMobile: 1001,
    cancelModal: 1001,
};

export const NEW_TARIFFS_START_DATE_STRING = '2024-09-01 00:01';
export const APPLICATION_SUBDOMAIN = reservedFrontendUrls.appUrl;
export const ZENAMU_LANDING_PAGE_HOSTNAME = process.env.REACT_APP_LANDING_PAGE_HOST ?? 'https://zenamu.com';
export const ZENAMU_PUBLIC_CALENDAR_PAGE_HOSTNAME = 'https://app.zenamu.com';
export const LECTURE_DIFFICULTY_SCALE = 3;
export const ANT_DATE_FORMAT = 'DD.MM.YYYY';
export const ANT_TIME_FORMAT = 'HH:mm';
export const MORE_LECTURE_RESERVATIONS_COUNT_MIN = 2;
export const MORE_LECTURE_RESERVATIONS_COUNT_MAX = 5;
export const DEFAULT_LECTURE_CAPACITY = 10;
export const DEFAULT_LECTURE_ONLINE_CAPACITY = 20;
export const DEFAULT_LECTURE_DIFFICULTY = 1;

export const ONE_WORKSHOP_EVENT_HEIGHT = 40;

export const PAGE_MAX_WIDTH = 1200;

export const API_IPIFY_URL = 'https://api.ipify.org?format=json';

export const DEFAULT_DURATION_FOR_LECTURE = 60;
export const MIN_DURATION_FOR_LECTURE = 30;
export const MAX_DURATION_FOR_LECTURE = 1020;

export const SMS_MESSAGE_MAX_DESCRIPTION = 140;

export const PAYMENT_METHOD_LONG_DESCRIPTION_MAX = 300;
export const PAYMENT_METHOD_SHORT_DESCRIPTION_MAX = 200;

export const CALENDAR_VIEW_LIST = 'list';
export const CALENDAR_VIEW_CALENDAR = 'calendar';

export const appLocales = appLanguages;

export const settingsItemContentTypes = {
    detail: 'detail',
    new: 'new',
    edit: 'edit',
};

export const SETTINGS_PLACE_STUDIO = 'studio';
export const SETTINGS_PLACE_CUSTOM = 'custom';

export const DEFAULT_LECTURE_BG_COLORS_ARRAY = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
];

export const COLORS_PALETTE = {
    DEFAULT: DEFAULT_LECTURE_BG_COLORS_ARRAY,
    GREEN: ['#b4d6c1', '#8dc3a7', '#6baf92', '#4e9c81', '#358873', '#207567'],
    BLUE: ['#66d7eb', '#51acc5', '#3e849e', '#2c5f78', '#1c3d52', '#0e1e28'],
    RED: ['#d9a18b', '#c87555', '#bc603d', '#a15334', '#86452b', '#50291a'],
    BROWN: ['#ddc9ab', '#c9a878', '#b28647', '#99733d', '#7f6032', '#4c391e'],
    GRAY: ['#cfc4b9', '#b1a08f', '#937c66', '#695848', '#54473a', '#3f352b'],
};

export const ACCEPTED_IMAGE_FORMATS_ALL = ['image/jpeg', 'image/png', 'image/webp'];
export const ACCEPTED_IMAGE_FORMATS_STANDART = ['image/jpeg', 'image/png'];

export const CLIENTS_TAGS_BG_COLORS_ARRAY = [
    '#FFC0CB',
    '#FF0000',
    '#FFA500',
    '#00FFFF',
    '#00FF00',
    '#0000FF',
    '#A020F0',
    '#ff00ff',
    '#FFD700',
    '#32CD32',
];

export const DEFAULT_LABELS_BG_COLORS_ARRAY = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
];

export const WarningTextColor = {
    GRAY: '#e4e4e4',
    RED: '#ff0000',
    GREEN: '#02ab02',
    ORANGE: '#ffa500',
};

// validation messages keys to translation
export const BOOLEAN_REQUIRED = 'errorNs:forms.validation.booleanRequired';
export const INCORRECT_EMAIL_FORMAT = 'errorNs:validations.INVALID_EMAIL_FORMAT';
export const RESTRICTED_ECOMAIL_EMAIL_FORMAT = 'errorNs:validations.RESTRICTED_ECOMAIL_EMAIL_FORMAT';
export const RESTRICTED_ECOMAIL_EMAIL_FORMAT_MORE_INFO =
    'errorNs:validations.RESTRICTED_ECOMAIL_EMAIL_FORMAT_MORE_INFO';
export const TOO_SMALL_NUMBER = 'errorNs:forms.validation.tooSmallNumber';
export const TOO_LONG_NUMBER = 'errorNs:forms.validation.tooLongNumber';
export const TOO_SHORT_PASSWORD = 'errorNs:forms.validation.tooShortPassword';
export const TOO_LONG_PASSWORD = 'errorNs:forms.validation.tooLongPassword';
export const TOO_SHORT_TEXT = 'errorNs:forms.validation.tooShortText';
export const TOO_LONG_TEXT = 'errorNs:forms.validation.tooLongText';
export const TOO_SHORT_NAME = 'formsNs:userRegistration.tooShortName';
export const TOO_SHORT_LAST_NAME = 'formsNs:userRegistration.tooShortLastName';
export const TOO_LONG_NAME = 'formsNs:userRegistration.tooLongName';
export const TOO_LONG_NAME_MAX_CHARACTERS = 40;
export const TOO_LONG_LAST_NAME = 'formsNs:userRegistration.tooLongLastName';
export const REQUIRED_CHECKED_FIELD = 'errorNs:forms.validation.requiredChecked';
export const REQUIRED_FIELD = 'errorNs:forms.validation.required';
export const DAY_FORMAT = 'errorNs:forms.validation.dayFormat';
export const AT_LEAST_ONE_CHOICE = 'errorNs:forms.validation.atLeastOneChoiceIsRequired';
export const MUST_BE_NUMBER = 'errorNs:forms.validation.mustBeNumber';
export const MUST_BE_VALID_TIME_UNIT = 'errorNs:forms.validation.mustBeValidTimeUnit';
export const NOT_ENOUGH_CREDITS = 'errorNs:forms.validation.notEnoughCredits';
export const NOT_ENOUGH_ENTRIES = 'errorNs:forms.validation.notEnoughEntries';
export const IS_EXPIRED_CREDIT = 'errorNs:forms.validation.isExpiredCredit';
export const IS_EXPIRED_PASS = 'errorNs:forms.validation.isExpiredPass';
export const ONLY_WITH_CREDIT = 'errorNs:forms.validation.onlyWithCredit';
export const ONLY_WITH_PASS = 'errorNs:forms.validation.onlyWithPass';
export const ONLY_PNG_OR_JPG_IMAGE = 'errorNs:forms.validation.onlyPngOrJpgImage';
export const IMAGE_SIZE_SMALLER_THAN_18MB = 'errorNs:forms.validation.imageSizeSmallerThan18MB';
export const NOT_EMPTY_TEXT_NAME = 'errorNs:forms.validation.notEmptyTextName';
export const PASSWORDS_DONT_MATCH = 'errorNs:forms.validation.passwordDontMatch';
export const CONFIRM_PASSWORD_REQUIRED = 'errorNs:forms.validation.confirmPasswordRequired';

// FIXME sjednotit nekam s API (codebooks?)
export const ALLOWED_ONLINE_PAYMENT_METHOD_TYPES = new Set([
    paymentMethodType.CARD,
    paymentMethodType.CREDIT,
    paymentMethodType.PASS,
    paymentMethodType.BANK_ACCOUNT,
]);

export const ALLOWED_WORKSHOP_AND_COURSE_PAYMENT_METHOD_TYPES = new Set([
    paymentMethodType.CARD,
    paymentMethodType.BANK_ACCOUNT,
    paymentMethodType.CREDIT,
    paymentMethodType.PASS,
    paymentMethodType.ON_PLACE,
]);

export const ALLOWED_ONLINE_PAYMENT_METHOD_TYPES_FOR_WAITLIST = new Set([
    paymentMethodType.CREDIT,
    paymentMethodType.PASS,
]);

export const API_ENVIRONMENT_HOST =
    process.env.NODE_ENV !== 'production' ? `${config.API_HOST}:${Number(config.API_PORT)}` : `${config.API_HOST}`;

export const WS_ENVIRONMENT_HOST =
    process.env.NODE_ENV !== 'production' ? `${config.WS_HOST}:${Number(config.API_PORT)}` : `${config.WS_HOST}`;

export const WORKSHOP_CONTENT_INFO = 'WORKSHOP_CONTENT_INFO';
export const WORKSHOP_CONTENT_ATTENDEES = 'WORKSHOP_CONTENT_ATTENDEES';
export const WORKSHOP_CONTENT_MAIL_TO_ALL = 'WORKSHOP_CONTENT_MAIL_TO_ALL';
export const WORKSHOP_CONTENT_RESERVE = 'WORKSHOP_CONTENT_RESERVE';
export const WORKSHOP_CONTENT_EDIT = 'WORKSHOP_CONTENT_EDIT';

export const COURSE_ADMIN = 'COURSE_ADMIN';
export const COURSE_CONTENT_INFO = 'COURSE_CONTENT_INFO';
export const COURSE_CONTENT_ATTENDEES = 'COURSE_CONTENT_ATTENDEES';
export const COURSE_CONTENT_RESERVE = 'COURSE_CONTENT_RESERVE';
export const COURSE_CONTENT_MAIL_TO_ALL = 'COURSE_CONTENT_MAIL_TO_ALL';
export const COURSE_CONTENT_EDIT = 'COURSE_CONTENT_EDIT';
