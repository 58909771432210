import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/sk';
import 'moment/locale/hu';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../App.less';

import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import csCZ from 'antd/lib/locale/cs_CZ';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import huHU from 'antd/lib/locale/hu_HU';
import itIT from 'antd/lib/locale/it_IT';
import plPL from 'antd/lib/locale/pl_PL';
import ptBR from 'antd/lib/locale/pt_BR';
import skSK from 'antd/lib/locale/sk_SK';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

import App from '../App';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { Offline } from '../components/Offline';
import { ZenamuLayout } from '../components/ZenamuLayout';
import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { useLoggedIn } from '../hooks/apollo/useLoggedIn';
import { AuthorizedLayoutComponent } from '../layout/AuthorizedLayoutComponent';
import { PublicLayoutComponent } from '../layout/PublicLayoutComponent';
import { ErrorNetworkProblems } from '../pages/errors';
import { CookiesProvider } from './CookiesProvider';

// import 'antd/dist/antd.css';

const locales = {
    cs: csCZ,
    enUS,
    sk: skSK,
    hu: huHU,
    pl: plPL,
    es: esES,
    pt: ptBR,
    de: deDE,
    it: itIT,
    fr: frFR,
};

export const AppProviders = () => {
    const userAppLocale = useAppLocale();
    const isLoggedIn = useLoggedIn();

    // with sentry error boundary
    // https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

    moment.locale(userAppLocale);

    const boundaryContent = (
        <ConfigProvider locale={locales[userAppLocale]}>
            <ZenamuLayout>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </ZenamuLayout>
        </ConfigProvider>
    );

    return (
        <I18nextProvider i18n={i18next}>
            {process.env.NODE_ENV === 'production' ? (
                <Sentry.ErrorBoundary
                    fallback={
                        <>
                            {isLoggedIn ? (
                                <AuthorizedLayoutComponent isSidebarVisible={false}>
                                    <ErrorNetworkProblems />
                                </AuthorizedLayoutComponent>
                            ) : (
                                <PublicLayoutComponent>
                                    <ErrorNetworkProblems />
                                </PublicLayoutComponent>
                            )}
                        </>
                    }
                >
                    <Offline>{boundaryContent}</Offline>
                </Sentry.ErrorBoundary>
            ) : (
                <ErrorBoundary isLoggedIn={isLoggedIn}>
                    <Offline>{boundaryContent}</Offline>
                </ErrorBoundary>
            )}
        </I18nextProvider>
    );
};
