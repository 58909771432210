import { useMutation } from '@apollo/client';
import { fyoogaMutations } from '@fyooga/graphql-requests';
import { Dropdown } from 'antd';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { appLocales } from '../../constants';
import { useAppLocale } from '../../hooks/apollo/useAppLocale';
import { UserMenuWrapper } from '../../layout/menu/UserProfileDropdown';
import { setAppLocale } from '../../lib/apollo/cache';
import { handleApolloServerRequestErrors } from '../../lib/apollo/errors';
import { getLanguageCodeByCountryCode } from '../../util/language';
import { Caret } from '../ui/Caret';
import { ContentLoading } from '../ui/ContentLoading';
import { zenamuSupportedLanguages } from './languagesMenu';
import { MoreLangModal } from './MoreLangModal';

export const LanguageSwitcher = ({ userId = null }: { userId?: number | null }) => {
    const { t } = useTranslation();
    const userAppLocale = useAppLocale();
    const [isActive, setIsActive] = useState(false);
    const [switchingLanguageLoading, setSwitchingLanguageLoading] = useState(false);
    const [isMoreLanguagesModalVisible, setIsMoreLanguagesModalVisible] = useState(false);
    const [callUpdateUserById] = useMutation(fyoogaMutations.updateUserById);

    const storeLanguageLocally = async (lang: string) => {
        await i18next.changeLanguage(lang);
        setAppLocale(lang);
        window.location.reload(); // refresh the browser to prevent cached values (ZEN-436)
    };

    const userPreferredItem = zenamuSupportedLanguages[userAppLocale];
    let menuLanguages;
    menuLanguages = userAppLocale.startsWith('en')
        ? [zenamuSupportedLanguages[appLocales.EN]]
        : [userPreferredItem].concat(zenamuSupportedLanguages[appLocales.EN]);
    const modalLanguages = Object.values(zenamuSupportedLanguages);

    const switchLanguage = async (value: string) => {
        setSwitchingLanguageLoading(true);
        try {
            if (userId) {
                await callUpdateUserById({
                    variables: {
                        userId,
                        input: { lang: value },
                    },
                });
            }
            await storeLanguageLocally(value);
        } catch (err: any) {
            handleApolloServerRequestErrors(err);
        } finally {
            setSwitchingLanguageLoading(false);
        }
    };

    return (
        <UserMenuWrapper>
            <MoreLangModal
                visible={isMoreLanguagesModalVisible}
                setIsMoreLanguagesModalVisible={setIsMoreLanguagesModalVisible}
                additionalLanguages={modalLanguages}
                switchLanguage={switchLanguage}
            />
            <Dropdown
                trigger={['click']}
                placement="bottomRight"
                onOpenChange={visible => setIsActive(visible)}
                menu={{
                    items: [
                        ...menuLanguages.map(({ key, label }) => ({
                            key,
                            label,
                        })),
                        {
                            key: 'more',
                            label: `${t('more')}...`,
                            onClick: () => setIsMoreLanguagesModalVisible(true),
                        },
                    ],
                    onClick: async event => {
                        const { key } = event;
                        if (key !== 'more') {
                            setIsActive(false);
                            await switchLanguage(key);
                        }
                    },
                }}
                overlayStyle={{ minWidth: '200px' }}
            >
                <div className="userMenu-profile">
                    {!switchingLanguageLoading ? (
                        <>
                            <span className="language__name">{getLanguageCodeByCountryCode(userAppLocale)}</span>
                            <Caret isActive={isActive} />
                        </>
                    ) : (
                        <span className="language__name">
                            <ContentLoading />
                        </span>
                    )}
                </div>
            </Dropdown>
        </UserMenuWrapper>
    );
};
