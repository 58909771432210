import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const Header = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <div className={classNames('zenamu-header')}>{children}</div>
        </>
    );
};

export const HeaderMobile = ({ children }: { children: ReactNode }) => {
    return <div className={classNames('zenamu-header-mobile')}>{children}</div>;
};
