import { appLanguages, stripeTariffs, tariffs } from '@fyooga/codebook';

import { activationFreelancer, activationStudio, registrationStudio } from '../assets/images';
import i18n from '../lib/i18n';
import { SubscriptionTariffT } from '../types/subscription';

export const isTariffFree = (tariffName?: string): boolean => {
    return tariffName === tariffs.FREE.name;
};

export const isTariffTrial = (tariffName?: string): boolean => {
    if (tariffName) {
        return tariffName.includes('TRIAL');
    }

    return false;
};

export const isDeprecatedFreelancerTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.FREELANCER_2021_MONTH_CZK.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_CZK.name ||
            tariffName === tariffs.FREELANCER_2021_MONTH_EUR.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_EUR.name ||
            tariffName === tariffs.FREELANCER_2021_MONTH_USD.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_GBP.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_GBP.name
        );
    }

    return false;
};

export const isLiteTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.LITE_2024_MONTH_CZK.name ||
            tariffName === tariffs.LITE_2024_YEAR_CZK.name ||
            tariffName === tariffs.LITE_2024_MONTH_EUR.name ||
            tariffName === tariffs.LITE_2024_YEAR_EUR.name ||
            tariffName === tariffs.LITE_2024_MONTH_USD.name ||
            tariffName === tariffs.LITE_2024_YEAR_USD.name ||
            tariffName === tariffs.LITE_2024_MONTH_HUF.name ||
            tariffName === tariffs.LITE_2024_YEAR_HUF.name ||
            tariffName === tariffs.LITE_2024_MONTH_PLN.name ||
            tariffName === tariffs.LITE_2024_YEAR_PLN.name ||
            tariffName === tariffs.LITE_2024_MONTH_GBP.name ||
            tariffName === tariffs.LITE_2024_YEAR_GBP.name
        );
    }

    return false;
};

export const isProfessionalTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_CZK.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_CZK.name ||
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_EUR.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_EUR.name ||
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_USD.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_USD.name ||
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_HUF.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_HUF.name ||
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_PLN.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_PLN.name ||
            tariffName === tariffs.PROFESSIONAL_2024_MONTH_GBP.name ||
            tariffName === tariffs.PROFESSIONAL_2024_YEAR_GBP.name
        );
    }

    return false;
};

export const isExpertTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.EXPERT_2024_MONTH_CZK.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_CZK.name ||
            tariffName === tariffs.EXPERT_2024_MONTH_EUR.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_EUR.name ||
            tariffName === tariffs.EXPERT_2024_MONTH_HUF.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_HUF.name ||
            tariffName === tariffs.EXPERT_2024_MONTH_PLN.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_PLN.name ||
            tariffName === tariffs.EXPERT_2024_MONTH_USD.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_USD.name ||
            tariffName === tariffs.EXPERT_2024_MONTH_GBP.name ||
            tariffName === tariffs.EXPERT_2024_YEAR_GBP.name
        );
    }

    return false;
};

export const isUltimateTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.ULTIMATE_2025_MONTH_CZK.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_CZK.name ||
            tariffName === tariffs.ULTIMATE_2025_MONTH_EUR.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_EUR.name ||
            tariffName === tariffs.ULTIMATE_2025_MONTH_HUF.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_HUF.name ||
            tariffName === tariffs.ULTIMATE_2025_MONTH_PLN.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_PLN.name ||
            tariffName === tariffs.ULTIMATE_2025_MONTH_USD.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_USD.name ||
            tariffName === tariffs.ULTIMATE_2025_MONTH_GBP.name ||
            tariffName === tariffs.ULTIMATE_2025_YEAR_GBP.name
        );
    }

    return false;
};

export const isDeprecatedStudioTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.STUDIO_2021_MONTH_CZK.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_CZK.name ||
            tariffName === tariffs.STUDIO_2021_MONTH_EUR.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_EUR.name ||
            tariffName === tariffs.STUDIO_2021_MONTH_USD.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_GBP.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_GBP.name
        );
    }

    return false;
};

const getTariffNameForStripeOrThePay = (langCode: string, tariffName: string, monthly: boolean) => {
    const isTrial = isTariffTrial(tariffName);
    const isFreeTariff = isTariffFree(tariffName);

    if (isTrial || isFreeTariff) {
        return {
            name: `${tariffs[tariffName][langCode].name}`,
            description: tariffs[tariffName][langCode].description,
        };
    }

    return {
        name: `${tariffs[tariffName][langCode].name}${
            monthly ? '' : ` (${i18n.t('subscriptionNs:tariffInfo.tariffLength.annual')})`
        }`,
        description: tariffs[tariffName][langCode].description,
    };
};

const findTariffToUpgrade = (
    allNewPeriodTariffs: SubscriptionTariffT[],
    baseName: string,
    currency: string,
    isMonthly: boolean,
) => {
    return allNewPeriodTariffs.find(
        tariff =>
            tariff.currency === currency && tariff.monthly === isMonthly && tariff.name === `${baseName}_${currency}`,
    );
};

const getPossibleTariffsToChange = (
    allNewPeriodTariffs: SubscriptionTariffT[],
    currency: string,
    monthlyTariffNames: string[],
    yearlyTariffNames: string[],
) => {
    const possibleTariffs: SubscriptionTariffT[] = [];

    monthlyTariffNames.forEach(name => {
        const tariff = findTariffToUpgrade(allNewPeriodTariffs, name, currency, true);
        if (tariff) possibleTariffs.push(tariff);
    });

    yearlyTariffNames.forEach(name => {
        const tariff = findTariffToUpgrade(allNewPeriodTariffs, name, currency, false);
        if (tariff) possibleTariffs.push(tariff);
    });

    return possibleTariffs;
};

export const getPossibleTariffsToUpgradeForTariff = (
    tariff: SubscriptionTariffT,
    allNewPeriodTariffs: SubscriptionTariffT[],
) => {
    const { name: currentTariff, currency } = tariff;

    // deprecated tariffs
    if (currentTariff.startsWith('STUDIO_2024_MONTH_COMPATIBILITY')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, [], ['STUDIO_2024_YEAR_COMPATIBILITY']);
    } else if (currentTariff.startsWith('STUDIO_2024_YEAR_COMPATIBILITY')) {
        return [];
    } else if (currentTariff.startsWith('FREELANCER_2024_MONTH_COMPATIBILITY')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['STUDIO_2024_MONTH_COMPATIBILITY'],
            ['FREELANCER_2024_YEAR_COMPATIBILITY', 'STUDIO_2024_YEAR_COMPATIBILITY'],
        );
    } else if (currentTariff.startsWith('FREELANCER_2024_YEAR_COMPATIBILITY')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, [], ['STUDIO_2024_YEAR_COMPATIBILITY']);
    } else if (currentTariff.startsWith('LITE_2024_MONTH')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['PROFESSIONAL_2024_MONTH', 'EXPERT_2024_MONTH', 'ULTIMATE_2025_MONTH'],
            ['LITE_2024_YEAR', 'PROFESSIONAL_2024_YEAR', 'EXPERT_2024_YEAR', 'ULTIMATE_2025_YEAR'],
        );
    } else if (currentTariff.startsWith('LITE_2024_YEAR')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            [],
            ['PROFESSIONAL_2024_YEAR', 'EXPERT_2024_YEAR', 'ULTIMATE_2025_YEAR'],
        );
    } else if (currentTariff.startsWith('PROFESSIONAL_2024_MONTH')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['EXPERT_2024_MONTH', 'ULTIMATE_2025_MONTH'],
            ['PROFESSIONAL_2024_YEAR', 'EXPERT_2024_YEAR', 'ULTIMATE_2025_YEAR'],
        );
    } else if (currentTariff.startsWith('PROFESSIONAL_2024_YEAR')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            [],
            ['EXPERT_2024_YEAR', 'ULTIMATE_2025_YEAR'],
        );
    } else if (currentTariff.startsWith('EXPERT_2024_MONTH')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['ULTIMATE_2025_MONTH'],
            ['EXPERT_2024_YEAR', 'ULTIMATE_2025_YEAR'],
        );
    } else if (currentTariff.startsWith('EXPERT_2024_YEAR')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, [], ['ULTIMATE_2025_YEAR']);
    } else if (currentTariff.startsWith('ULTIMATE_2025_MONTH')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, [], ['ULTIMATE_2025_YEAR']);
    } else if (currentTariff.startsWith('ULTIMATE_2025_YEAR')) {
        return [];
    }

    return [];
};

export const getPossibleTariffsToDowngradeForTariff = (
    tariff: SubscriptionTariffT,
    allNewPeriodTariffs: SubscriptionTariffT[],
) => {
    const { name: currentTariff, currency } = tariff;

    // deprecated tariffs
    if (currentTariff.startsWith('STUDIO_2024_MONTH_COMPATIBILITY')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['FREELANCER_2024_MONTH_COMPATIBILITY'],
            ['FREELANCER_2024_YEAR_COMPATIBILITY'],
        );
    } else if (currentTariff.startsWith('STUDIO_2024_YEAR_COMPATIBILITY')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['STUDIO_2024_MONTH_COMPATIBILITY', 'FREELANCER_2024_MONTH_COMPATIBILITY'],
            ['FREELANCER_2024_YEAR_COMPATIBILITY'],
        );
    } else if (currentTariff.startsWith('FREELANCER_2024_MONTH_COMPATIBILITY')) {
        return [];
    } else if (currentTariff.startsWith('FREELANCER_2024_YEAR_COMPATIBILITY')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, ['FREELANCER_2024_MONTH_COMPATIBILITY'], []);
    } else if (currentTariff.startsWith('LITE_2024_MONTH')) {
        return [];
    } else if (currentTariff.startsWith('LITE_2024_YEAR')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, ['LITE_2024_MONTH'], []);
    } else if (currentTariff.startsWith('PROFESSIONAL_2024_MONTH')) {
        return getPossibleTariffsToChange(allNewPeriodTariffs, currency, ['LITE_2024_MONTH'], ['LITE_2024_YEAR']);
    } else if (currentTariff.startsWith('PROFESSIONAL_2024_YEAR')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['LITE_2024_MONTH', 'PROFESSIONAL_2024_MONTH'],
            ['LITE_2024_YEAR'],
        );
    } else if (currentTariff.startsWith('EXPERT_2024_MONTH')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['LITE_2024_MONTH', 'PROFESSIONAL_2024_MONTH'],
            ['LITE_2024_YEAR', 'PROFESSIONAL_2024_YEAR'],
        );
    } else if (currentTariff.startsWith('EXPERT_2024_YEAR')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['LITE_2024_MONTH', 'EXPERT_2024_MONTH', 'PROFESSIONAL_2024_MONTH'],
            ['LITE_2024_YEAR', 'PROFESSIONAL_2024_YEAR'],
        );
    } else if (currentTariff.startsWith('ULTIMATE_2025_MONTH')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['LITE_2024_MONTH', 'PROFESSIONAL_2024_MONTH', 'EXPERT_2024_MONTH'],
            ['LITE_2024_YEAR', 'PROFESSIONAL_2024_YEAR', 'EXPERT_2024_YEAR'],
        );
    } else if (currentTariff.startsWith('ULTIMATE_2025_YEAR')) {
        return getPossibleTariffsToChange(
            allNewPeriodTariffs,
            currency,
            ['LITE_2024_MONTH', 'PROFESSIONAL_2024_MONTH', 'EXPERT_2024_MONTH', 'ULTIMATE_2025_MONTH'],
            ['LITE_2024_YEAR', 'PROFESSIONAL_2024_YEAR', 'EXPERT_2024_YEAR'],
        );
    }

    return [];
};

export const getTariffNameAndDescriptionByCurrentLanguage = (tariffName: string, monthly: boolean, lang: string) => {
    if (lang === appLanguages.CS) {
        return getTariffNameForStripeOrThePay('cs', tariffName, monthly);
    }
    if (lang === appLanguages.SK) {
        return getTariffNameForStripeOrThePay('sk', tariffName, monthly);
    }
    if (lang === appLanguages.HU) {
        return getTariffNameForStripeOrThePay('hu', tariffName, monthly);
    }
    if (lang === appLanguages.PL) {
        return getTariffNameForStripeOrThePay('pl', tariffName, monthly);
    }
    if (lang === appLanguages.ES) {
        return getTariffNameForStripeOrThePay('es', tariffName, monthly);
    }
    if (lang === appLanguages.PT) {
        return getTariffNameForStripeOrThePay('pt-BR', tariffName, monthly);
    }
    if (lang === appLanguages.DE) {
        return getTariffNameForStripeOrThePay('de', tariffName, monthly);
    }
    if (lang === appLanguages.IT) {
        return getTariffNameForStripeOrThePay('it', tariffName, monthly);
    }
    if (lang === appLanguages.FR) {
        return getTariffNameForStripeOrThePay('fr', tariffName, monthly);
    }
    return getTariffNameForStripeOrThePay('en', tariffName, monthly);
};

export const getClassNameByTariffName = (tariffName: string) => {
    if (isLiteTariff(tariffName)) {
        return 'tarif-lite';
    } else if (isProfessionalTariff(tariffName)) {
        return 'tarif-professional';
    } else if (isExpertTariff(tariffName)) {
        return 'tarif-expert';
    } else if (isUltimateTariff(tariffName)) {
        return 'tarif-ultimate';
    } else if (isDeprecatedFreelancerTariff(tariffName)) {
        return 'tarif-professional';
    } else if (isDeprecatedStudioTariff(tariffName)) {
        return 'tarif-expert';
    }

    return 'tarif-free';
};
