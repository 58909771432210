import { ApolloClient, ApolloError, NormalizedCacheObject, ServerError, ServerParseError } from '@apollo/client';
import { formFeedback, formField, handledApiErrors } from '@fyooga/errors';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import { FieldError } from 'react-hook-form';

import history from '../../history';
import { commonRoutes } from '../../router/constants';
import { makeUserLogout } from '../../services/auth/authProvider';
import i18n from '../i18n';
import { showErrorToast } from '../toasts';
import { isForbiddenErrorVar, isNetworkErrorVar, isUnauthorizedErrorVar } from './cache';

/*
formField:
- known (to us) errors that should be visible beside form field.

formFeedback:
- known errors (to us) coming from Rest API and GraphQL API to be shown above the form

handledApiErrors:
-  also for form feedback
 */

// Should focus the input during setting an error.
// This only works when the input's reference is registered, it will not work for custom register as well.
type SetErrorConfigT = {
    shouldFocus?: boolean;
};

type FormFieldError = {
    code: string;
    fieldName: string;
    description: string;
};

type ApiApolloError = {
    code: string;
    description: string;
};

export type SetErrorT = (name: string, error: FieldError, props?: SetErrorConfigT) => void;

/**
 * Check for invalid graphql schema request (wrong type, field has to be required etc.)
 * @param networkError
 */
const isGraphQlSchemaValidationError = (networkError: any): boolean => {
    let badUserInputFounded = false;
    const networkErrors = networkError?.result?.errors ?? [];
    if (networkErrors.length) {
        badUserInputFounded = networkErrors.find((error: any) => error?.extensions?.code === 'BAD_USER_INPUT');
    }
    return networkError?.statusCode === 400 && badUserInputFounded;
};

/**
 * To check if some of API errors are unknown (not added in errors package)
 */
const getHandledCodes = (): Array<FormFieldError | ApiApolloError> => {
    const handledCodes: Array<FormFieldError | ApiApolloError> = [];

    Object.keys(formField).forEach(error => {
        handledCodes.push(formField[error]);
    });

    Object.keys(formFeedback).forEach(error => {
        handledCodes.push(formFeedback[error]);
    });

    return handledCodes;
};

/**
 * Check for apollo link, mutation operations and for form feedback GraphQl error results
 * @param graphQLErrors
 */
export const canHandleApolloErrors = (
    graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
    errorCodesArray: ReadonlyArray<FormFieldError | ApiApolloError>,
): boolean => {
    const handledCodes: Array<string> = [];
    if (graphQLErrors.length === 0) {
        return false;
    }
    for (const codeName in errorCodesArray) {
        handledCodes.push(errorCodesArray[codeName].code);
    }
    const uniqueHandledCodes = Array.from(new Set(handledCodes));
    // all errors known graphql errors?
    const knownFormFieldHandledError = graphQLErrors.every(graphQLError => {
        const errorCode = graphQLError.extensions?.code ?? '';
        return uniqueHandledCodes.includes(errorCode as string);
    });
    return knownFormFieldHandledError;
};

/**
 * Show some error coming from Rest API above the form where a request is coming from.
 * @param apolloError
 */
export const isKnownApiFormFeedbackError = (code: string) => {
    const handledCodes: string[] = [];
    for (const codeName in handledApiErrors) {
        handledCodes.push(handledApiErrors[codeName].code);
    }
    for (const codeName in formFeedback) {
        handledCodes.push(formFeedback[codeName].code);
    }
    const uniqueHandledCodes = Array.from(new Set(handledCodes));
    return uniqueHandledCodes.includes(code);
};

/**
 * Show some error comming from GraphQl API above the form where a request is coming from.
 * @param apolloError
 */
export const isKnownApolloFeedbackError = (apolloError: ApolloError) => {
    const handledCodes: string[] = [];
    if (apolloError.graphQLErrors.length === 0) {
        return false;
    }

    for (const codeName in formFeedback) {
        handledCodes.push(formFeedback[codeName].code);
    }

    const isFormFeedbackApolloError = apolloError.graphQLErrors.every(graphQLError => {
        const errorCode = graphQLError.extensions?.code ?? '';
        return handledCodes.includes(errorCode as string);
    });

    return (
        apolloError?.graphQLErrors &&
        (canHandleApolloErrors(apolloError.graphQLErrors, handledApiErrors) || isFormFeedbackApolloError)
    );
};

/**
 * 1. process apollo errors related to authentication and authorization returned from apollo graphql server
 * 2. process unknown apollo graphql errors (errors not handled in catch while calling mutations and cache modifications)
 * 3. process general network errors
 * @param graphQLErrors
 * @param networkError
 * @param client
 */
export const processAuthAndUnknownGraphQlAndNetworkApolloErrors = (
    graphQLErrors: GraphQLFormattedError[] | undefined,
    networkError: Error | ServerError | ServerParseError | undefined,
    client: ApolloClient<NormalizedCacheObject>,
) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err?.extensions?.code) {
                switch (err.extensions.code) {
                    case 'UNAUTHENTICATED': {
                        makeUserLogout(client).then(() => {
                            isUnauthorizedErrorVar(true);
                            history.push(`/${commonRoutes.LOGIN}`);
                        });
                        break;
                    }
                    // handle other errors
                    case 'FORBIDDEN':
                        makeUserLogout(client).then(() => {
                            isUnauthorizedErrorVar(true);
                            history.push(`/${commonRoutes.LOGIN}`);
                        });
                        break;
                    default:
                }
            }
        }
    }

    // @ts-ignore
    const { statusCode } = networkError ?? { statusCode: undefined };

    const unknownApolloServerError = graphQLErrors && !canHandleApolloErrors(graphQLErrors, getHandledCodes());

    if (statusCode === 401) {
        makeUserLogout(client).then(() => {
            history.push(`/${commonRoutes.LOGIN}`);
        });
    } else if (statusCode === 403) {
        isForbiddenErrorVar(true);
        return;
    } else if (unknownApolloServerError || networkError) {
        if (networkError) {
            console.error('NETWORK ERROR: ', networkError);
            console.error('NETWORK ERROR statusCode: ', statusCode);
            console.error('NETWORK ERROR: ', networkError.message);

            if (isGraphQlSchemaValidationError(networkError)) {
                showErrorToast(i18n.t(`errorNs:validations.INVALID_INPUT`));
            } else {
                // in case when the whole api is down or something is corrupted badly in graphql schema
                isNetworkErrorVar(true);
            }
        } else if (graphQLErrors) {
            // This is for unknown graphql errors coming from API (not added to form fields, form feedback etc.)
            const errorsToShow = new Set(); // To prevent show an errors with the same code multiple times (multiple toasts)
            graphQLErrors.map(graphQLError => {
                if (graphQLError.extensions?.code) {
                    switch (graphQLError.extensions.code) {
                        case 'FYOOGA_YUP_VALIDATION_ERROR': {
                            const translation = i18n.t(`errorNs:validations.${graphQLError.message}`);
                            errorsToShow.add(translation);
                            break;
                        }
                        default: {
                            // Unauthenticated is handled separately in makeUserLogout
                            if (graphQLError.extensions.code !== 'UNAUTHENTICATED') {
                                errorsToShow.add(`${i18n.t(`errorNs:${graphQLError.extensions.code}`)}`);
                            }
                        }
                    }
                } else {
                    // graph ql error but without code... This should not occur most probably...
                    errorsToShow.add(
                        `${i18n.t('errorNs:errorPage.somethingWentWrong1')} ${i18n.t(
                            'errorNs:errorPage.somethingWentWrong2',
                        )}`,
                    );
                }
            });
            if (errorsToShow.size) {
                const errors = Array.from(errorsToShow);
                errors.forEach(errorTranslation => {
                    showErrorToast(errorTranslation as string);
                });
            }
        } else {
            throw new TypeError('This should not occur, some weird not network error and even not graphql error');
        }
    }
};

/*
 * We can build the error handlers to be shown beside form input fields
 */
export const setFormFieldApiError = (fieldName: string, errorCode: string, setError: SetErrorT) => (): void => {
    const translation = i18n.t(`errorNs:${errorCode}`);
    setError(fieldName, { type: 'manual', message: translation });
};

const handleFormFieldApiValidationErrors = (
    graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
    handlers: any,
): void => {
    graphQLErrors.forEach(graphQLError => {
        const errorCode = graphQLError?.extensions?.code;
        if (errorCode) {
            const handler = handlers[`${errorCode}`];
            if (handler !== undefined) {
                handler();
            }
        }
    });
};

/**
 * This is error handler used mainly with calling GraphQl mutations and cache modifications
 * If setError function (from react-hook-form) is not passes, it is handled like some general error.
 *
 * Note: this is processed when the error is not handled by processAuthAndUnknownGraphQlAndNetworkApolloErrors
 * @param error
 * @param setError
 */
export const handleApolloServerRequestErrors = (error: ApolloError, setError: any | null = null): void => {
    // check if it is apollo error and even known apollo error for formField
    const knownFormFieldError = error?.graphQLErrors ? canHandleApolloErrors(error.graphQLErrors, formField) : false;
    if (error?.graphQLErrors && knownFormFieldError && setError) {
        let handlers = {};
        for (const codeName in formField) {
            handlers = {
                ...handlers,
                [formField[codeName].code]: setFormFieldApiError(
                    formField[codeName].fieldName,
                    formField[codeName].code,
                    setError,
                ),
            };
        }
        handleFormFieldApiValidationErrors(error.graphQLErrors, handlers);
    } else if (error instanceof ApolloError === false) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(error);
        }

        showErrorToast(`NOT APOLLO ERROR: ${error.message}`);
    } else {
        const knownFormFeedbackError = canHandleApolloErrors(error.graphQLErrors, formFeedback);
        const knownHandledApiError = canHandleApolloErrors(error.graphQLErrors, handledApiErrors);

        if (!knownHandledApiError && !knownFormFeedbackError) {
            // This should not be necessary, but we need to place all form feedbacks carefully to the places where it is used.
            showErrorToast(`Apollo Error: ${error.message}`);
        }
    }
};
