import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import noInternet from '../assets/images/noInternet.png';
import { APPLICATION_SUBDOMAIN } from '../constants';
import { InteractiveButton } from './buttons/Button';

export const OfflineContainer = styled.div<{ online: boolean; previousOnline: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1002;
    display: ${({ online, previousOnline }) => (previousOnline === online && online ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: ${({ online }) => (online ? 'slideOutUp' : 'slideInDown')} 0.5s ease-in-out;
    visibility: ${({ online }) => (online ? 'hidden' : 'visible')};
    opacity: ${({ online }) => (online ? 0 : 1)};
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    padding: 40px;

    @media (max-width: 767px) {
        padding: 20px;
    }
`;

export const OfflineContent = styled.div`
    padding: 15px 20px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    > svg {
        height: 50px;
        width: auto;
        margin-right: 20px;
    }
`;

export const OfflineText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    padding: 1rem 2rem;
`;

export const OfflineOverlay = styled.div`
    position: fixed;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
`;

export const Offline = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const [online, setOnline] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [offlineVisible, setOfflineVisible] = useState(false);
    const previousOnlineRef = useRef(online);

    const checkOnlineStatus = async (url: string) => {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok;
        } catch {
            return false;
        }
    };

    const updateOnlineStatus = async () => {
        const isOnline = await checkOnlineStatus('https://captive.apple.com');
        const finalOnlineStatus = isOnline || (await checkOnlineStatus('https://zenamu.com'));
        setOnline(finalOnlineStatus);
        setIsInitialized(true);
    };

    useEffect(() => {
        // Aktualizace ref při každé změně online
        previousOnlineRef.current = online;
    }, [online]);

    // Přístup k předchozímu stavu online
    const previousOnline = previousOnlineRef.current;

    useEffect(() => {
        updateOnlineStatus();

        const handleOnline = async () => {
            setOnline(true);
        };
        const handleOffline = () => {
            setOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        updateOnlineStatus();
    }, [location.pathname]);

    useEffect(() => {
        if (!isInitialized) return;

        if (!online) {
            setOfflineVisible(true);
            disableBodyScroll(document.body);
        } else {
            enableBodyScroll(document.body);
            const timer = setTimeout(() => setOfflineVisible(false), 500); // 500ms odpovídá délce animace
            return () => clearTimeout(timer);
        }
    }, [online, isInitialized]);

    const handleReload = () => {
        window.location.href = `/${APPLICATION_SUBDOMAIN}/`;
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const showIsOffline = isInitialized && offlineVisible;

    return (
        <>
            {showIsOffline && (
                <>
                    <OfflineContainer online={online} previousOnline={previousOnline}>
                        <OfflineContent>
                            <OfflineText>
                                <h2>No Internet Connection</h2>
                                <div>
                                    <img src={noInternet} width={150} height={150} alt="No internet" />
                                </div>
                                <p>Please check your internet connection and try again</p>
                                <InteractiveButton value={t('cacheBusting.button')} onClick={handleReload} />
                            </OfflineText>
                        </OfflineContent>
                    </OfflineContainer>
                    <OfflineOverlay className={!online ? 'visible' : ''} />
                </>
            )}
            {children}
        </>
    );
};
