import './SidebarDesktopMenu.less';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { sidebarItemIcon } from '../../../theme';
import { TooltipResponsive } from '../../TooltipResponsive';
import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/IconsSvgMap';

export interface MenuItemProps {
    linkTo: string;
    icon: IconName;
    title: string;
    isAccessible: boolean;
    className: string;
}

interface SidebarDesktopMenuProps {
    settingsItemsStartPath: string;
    sidebarLinks: MenuItemProps[];
}

export const SIDEBAR_SETTINGS_TITLE = 'sidebar.settings';

export const SidebarDesktopMenu = ({ settingsItemsStartPath, sidebarLinks }: SidebarDesktopMenuProps) => {
    const { t } = useTranslation();

    return (
        <div className="sidebar-left-menu">
            <div className="siderbar-corner-top" />
            <div className="sidebar-content">
                {sidebarLinks.map(menuItem => {
                    return (
                        <div key={`sidebar-item-${menuItem.title}`}>
                            {menuItem.isAccessible && (
                                <TooltipResponsive title={t(menuItem.title)} placement="right" mouseEnterDelay={0.5}>
                                    <div className={menuItem.className}>
                                        <NavLink
                                            exact
                                            key={menuItem.title}
                                            to={menuItem.linkTo}
                                            className="sidebar-menu__item"
                                            activeClassName="sidebar-menu__item-selected"
                                            isActive={(match, location) => {
                                                if (!match) {
                                                    if (menuItem.title === SIDEBAR_SETTINGS_TITLE) {
                                                        const { pathname } = location;

                                                        if (pathname.startsWith(settingsItemsStartPath)) {
                                                            return true;
                                                        }
                                                    }
                                                    return false;
                                                }

                                                return true;
                                            }}
                                        >
                                            <span>
                                                <Icon icon={menuItem.icon} size={27} iconColor={sidebarItemIcon} />
                                            </span>
                                        </NavLink>
                                    </div>
                                </TooltipResponsive>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="siderbar-corner-bottom" />
            {/* width and height = 0 otherwise it breaks footer - this clippath is for sidebar selected item */}
            <svg style={{ width: 0, height: 0 }}>
                <clipPath id="activeSidebarClip" clipPathUnits="objectBoundingBox">
                    <path d="m1,0.002 l0,1 c0,-0.104,-0.108,-0.189,-0.242,-0.189 l-0.531,0 c-0.125,0,-0.227,-0.079,-0.227,-0.177 l0,-0.267 c0,-0.098,0.102,-0.177,0.227,-0.177 l0.531,0 c0.134,0,0.242,-0.085,0.242,-0.189"></path>
                </clipPath>
            </svg>
        </div>
    );
};
