import React, { ReactNode } from 'react';

import { Header, HeaderMobile } from './Header';

export interface HeaderMobileWrapperProps {
    children: ReactNode;
    isMobileOrTablet: boolean;
}

export const HeaderMobileWrapper = ({ children, isMobileOrTablet }: HeaderMobileWrapperProps) => {
    if (!isMobileOrTablet) {
        return <Header>{children}</Header>;
    }

    return <HeaderMobile>{children}</HeaderMobile>;
};
