import { addDays, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipResponsive } from '../../components/TooltipResponsive';
import {
    UNLIMITED_ORDERS_CONSTANT,
    getMaxAllowedOrdersByTariff,
    useCheckTariffLimits,
} from '../../hooks/useCheckTariffLimits';
import { useViewport } from '../../hooks/useViewport';
import { useFormatters } from '../../lib/formatters';
import { OfficeT } from '../../types/office';
import { MaxOrdersProgressCircle, getFormattedLimit } from './MaxOrdersProgressCircle';

interface AuthorizedHeaderTariffLimitsProps {
    office?: OfficeT;
}

export const AuthorizedHeaderTariffLimits = ({ office }: AuthorizedHeaderTariffLimitsProps) => {
    const { t } = useTranslation();
    const { data } = useCheckTariffLimits(office);
    const { DateFormatter } = useFormatters();
    const { isMobajl } = useViewport();

    if (!data) {
        return null;
    }

    const isExpertAlaUltimate = office?.isExpertAlaUltimate ?? false;

    const {
        totalOrdersCount,
        maxAllowedOrdersCount,
        bookTariff,
        tariffMonthlyLimitValidFrom,
        tariffMonthlyLimitValidTo,
    } = data;

    const tariffName = bookTariff?.name;

    const remainingAllowedOrders = maxAllowedOrdersCount - totalOrdersCount;

    const ordersRemainingTranslation = t('plurals.order', {
        postProcess: 'interval',
        count: remainingAllowedOrders,
    });

    return (
        <>
            {tariffName &&
            getMaxAllowedOrdersByTariff(tariffName, isExpertAlaUltimate) !== UNLIMITED_ORDERS_CONSTANT ? (
                <>
                    <TooltipResponsive
                        title={t('subscriptionNs:freeRemainingOrders', {
                            remainingOrders: remainingAllowedOrders,
                            validFrom: DateFormatter.format(parseISO(tariffMonthlyLimitValidFrom), {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }),
                            validTo: DateFormatter.format(parseISO(tariffMonthlyLimitValidTo), {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }),
                            classPlural: ordersRemainingTranslation,
                        })}
                    >
                        {!isMobajl ? (
                            <div style={{ width: 150, display: 'flex', alignItems: 'center', paddingBottom: '3px' }}>
                                <MaxOrdersProgressCircle current={totalOrdersCount} max={maxAllowedOrdersCount} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 'medium',
                                    marginLeft: '10px',
                                }}
                            >
                                <b>{getFormattedLimit(totalOrdersCount, maxAllowedOrdersCount, false)}</b>
                            </div>
                        )}
                    </TooltipResponsive>
                </>
            ) : null}
        </>
    );
};
