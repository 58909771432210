import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AddressOutput } from '../components/AddressOutput';
import { LinkText } from '../components/LinkText';
import { useLocalizedZenamuLandingPageHost } from '../hooks/useLocalizedZenamuLandingPageHost';
import { currentPublicViewStudioVar, userIdVar } from '../lib/apollo/cache';
import { studioRoutes } from '../router/constants';
import { colorNotSoDarkText, primaryColor } from '../theme';
import { CookiePopup } from './CookiePopup';

interface FooterContainerProps {
    isPublic?: boolean;
}

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 70px 0 70px;
    gap: 15px;
    font-size: 15px;
    font-weight: 500;

    @media (max-width: 767px) {
        font-size: 13px;
        gap: 30px;
    }
`;

const FooterWrapperFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
`;

const FooterWrapperSecondRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    color: ${colorNotSoDarkText};
`;

const FooterWrapperFirstRowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
`;

const FooterWrapperFirstRowLeftLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width: 767px) {
        margin-top: 15px;
        align-items: center;
    }
`;

const FooterWrapperFirstRowRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`;

const FooterWrapperFirstRowRightContact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width: 767px) {
        margin-top: 15px;
    }
`;

export const FooterContainer = ({ isPublic = false }: FooterContainerProps) => {
    const { t } = useTranslation();
    const userId = useReactiveVar(userIdVar);
    const currentPublicViewStudio = useReactiveVar<any>(currentPublicViewStudioVar);
    const landingPageHost = useLocalizedZenamuLandingPageHost();

    const { data: officeAddressData } = useQuery(fyoogaQueries.getAddressByOfficeId, {
        variables: {
            officeId: currentPublicViewStudio?.officeId,
        },
        skip: !currentPublicViewStudio?.officeId,
    });

    return (
        <FooterWrapper>
            <FooterWrapperFirstRow>
                <FooterWrapperFirstRowLeft>
                    <FooterWrapperFirstRowLeftLinks>
                        {!isPublic && (
                            <>
                                <a href={`${landingPageHost}/docs/help`} target="_blank" rel="noreferrer">
                                    {t('docsHelp')}
                                </a>
                            </>
                        )}
                        <a href={`${landingPageHost}/docs/tos-gdpr/tos`} target="_blank" rel="noreferrer">
                            {t('zenamuTerms')}
                        </a>
                        <a href={`${landingPageHost}/docs/tos-gdpr/gdpr`} target="_blank" rel="noreferrer">
                            {t('zenamuGdpr')}
                        </a>
                        {isPublic && <CookiePopup />}
                    </FooterWrapperFirstRowLeftLinks>
                </FooterWrapperFirstRowLeft>
                {currentPublicViewStudio?.name && (
                    <FooterWrapperFirstRowRight>
                        <FooterWrapperFirstRowRightContact>
                            {currentPublicViewStudio?.name && <h3>{currentPublicViewStudio.name}</h3>}
                            {officeAddressData?.addressByOfficeId && (
                                <AddressOutput address={officeAddressData.addressByOfficeId} />
                            )}
                            {currentPublicViewStudio?.domain && (
                                <Link to={`/${currentPublicViewStudio.domain}/${studioRoutes.tos}`}>
                                    {t('calendarNs:identityHeader.terms')}
                                </Link>
                            )}
                        </FooterWrapperFirstRowRightContact>
                    </FooterWrapperFirstRowRight>
                )}
            </FooterWrapperFirstRow>
            <FooterWrapperSecondRow>
                <p>
                    <Trans
                        i18nKey="createdByZenamu"
                        components={{
                            zenamuLink: <LinkText to={landingPageHost} title="Zenamu" />,
                        }}
                    />
                </p>
            </FooterWrapperSecondRow>
        </FooterWrapper>
    );
};
