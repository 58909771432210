import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import { ReactNode } from 'react';

import { useViewport } from '../hooks/useViewport';

type TooltipResponsiveProps = TooltipProps & {
    title: ReactNode;
    children: ReactNode;
};

export const TooltipResponsive = ({ title, children, ...rest }: TooltipResponsiveProps) => {
    const { isMobajl } = useViewport();

    return (
        <Tooltip title={!isMobajl && title ? title : null} {...rest}>
            {children}
        </Tooltip>
    );
};
