import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { errorImage } from '../../assets/images';
import { InteractiveButton } from '../../components/buttons';
import { Page } from '../../components/ui/Page';
import { APPLICATION_SUBDOMAIN } from '../../constants';

export const ErrorNetworkProblems = () => {
    const { t } = useTranslation();
    /**
     * This is fallback because sometimes it fails from some weird reason that CORS headers are sent before
     */
    useEffect(() => {
        // we want to catch all errors in localhost and do not refresh in development
        if (process.env.NODE_ENV === 'production') {
            const hasRefreshedCount = JSON.parse(window.sessionStorage.getItem('error-refreshed-count') || '0');

            try {
                const hasRefreshedCountNumberValue = Number(hasRefreshedCount);
                if (hasRefreshedCountNumberValue === 0) {
                    window.sessionStorage.setItem('error-refreshed-count', '1'); // we are now going to refresh
                    window.location.reload(); // refresh the page
                }
            } catch (err) {}

            setTimeout(function () {
                // clear from sessionStorage after 5 minutes
                window.sessionStorage.removeItem('error-refreshed-count');
            }, 300000);
        }
    }, []);

    const handleReload = () => {
        window.location.href = `/${APPLICATION_SUBDOMAIN}/`;
        setTimeout(() => {
            window.location.reload();
        }, 100); // Počkejte, až se URL změní
    };
    return (
        <Page headerTitle={t('errorNs:errorPage.somethingWentWrongTitle')}>
            <p>{t('errorNs:errorPage.somethingWentWrong1')}</p>
            <img src={errorImage} height="auto" width={200} />
            <p className="mt-10">{t('errorNs:errorPage.somethingWentWrong2')}</p>
            <InteractiveButton value={t('cacheBusting.button')} onClick={handleReload} className="mt-10" />
        </Page>
    );
};
