import { appLocales } from '../../constants';

export const zenamuSupportedLanguages = {
    [appLocales.EN]: { key: appLocales.EN, label: 'English' },
    [appLocales.ES]: { key: appLocales.ES, label: 'Español' },
    [appLocales.CS]: { key: appLocales.CS, label: 'Čeština' },
    [appLocales.DE]: { key: appLocales.DE, label: 'Deutsch' },
    [appLocales.IT]: { key: appLocales.IT, label: 'Italiano' },
    [appLocales.HU]: { key: appLocales.HU, label: 'Magyar' },
    [appLocales.PL]: { key: appLocales.PL, label: 'Polski' },
    [appLocales.PT]: { key: appLocales.PT, label: 'Português (BR)' },
    [appLocales.SK]: { key: appLocales.SK, label: 'Slovenčina' },
    [appLocales.FR]: { key: appLocales.FR, label: 'Français' },
};
