import { SettingOutlined } from '@ant-design/icons';
import { officeUserRoles } from '@fyooga/codebook';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { APPLICATION_SUBDOMAIN } from '../constants';
import { useHasUserAccessByOfficeRole } from '../hooks/useHasUserAccessByOfficeRole';
import { useViewport } from '../hooks/useViewport';
import { APP_TAB_KEY_OFFICE } from '../router/constants';
import { borderRadiusBase, componentBackground } from '../theme';
import { InteractiveButton } from './buttons';

const IdentityHeaderSwitchToAdmin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${componentBackground};
    // posune záložku zpět o polovinu její šířky a výšky, čímž zajistí, že její střed bude přesně ve středu základního elementu.
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadiusBase};
    z-index: 15;

    @media (max-width: 767px) {
        transform: translate(0, -50%);
        border: 1px solid #d8d7d7;
    }
`;

const IdentityHeaderSwitchToAdminMobileStyles = styled.div``;

export const SwitchAdminMobileWrapper = ({ condition, children }: { condition: boolean; children: ReactNode }) => {
    return condition ? (
        <>
            <IdentityHeaderSwitchToAdmin>{children}</IdentityHeaderSwitchToAdmin>
        </>
    ) : (
        <IdentityHeaderSwitchToAdminMobileStyles>{children}</IdentityHeaderSwitchToAdminMobileStyles>
    );
};

export const SwitchAdmin = ({
    officeId,
    domain,
    withIcon = true,
}: {
    officeId: number;
    domain: string;
    withIcon?: boolean;
}) => {
    const { t } = useTranslation();
    const { isMobajl } = useViewport();

    const { hasAccess: isUserOfficeMember } = useHasUserAccessByOfficeRole(officeId, [
        officeUserRoles.OFFICIAL,
        officeUserRoles.MANAGER,
        officeUserRoles.SUPERADMIN,
    ]);

    if (!isUserOfficeMember) {
        return null;
    }

    return (
        <SwitchAdminMobileWrapper condition={!isMobajl}>
            <InteractiveButton
                icon={withIcon && <SettingOutlined />}
                type="link"
                href={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}`}
                value={t('calendarNs:calendar.privateStudioCalendar')}
            />
        </SwitchAdminMobileWrapper>
    );
};
