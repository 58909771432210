import { Input, Menu, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LangDataT } from '../../types/language';

export const MoreLangModal = ({
    visible,
    setIsMoreLanguagesModalVisible,
    additionalLanguages,
    switchLanguage,
}: {
    visible: boolean;
    setIsMoreLanguagesModalVisible: (visible: boolean) => void;
    additionalLanguages: LangDataT[];
    switchLanguage: (key: string) => void;
}) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    // Normalize function to remove diacritics
    const normalizeString = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Filtered languages ignoring diacritics
    const filteredLanguages = additionalLanguages.filter(lang =>
        normalizeString(lang.label.toLowerCase()).includes(normalizeString(searchText.toLowerCase())),
    );

    return (
        <Modal
            title={t('selectLanguage')}
            open={visible}
            onCancel={() => setIsMoreLanguagesModalVisible(false)}
            footer={null}
        >
            <Input
                placeholder={t('search')}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                style={{ marginBottom: '1rem' }}
            />
            <Menu
                onClick={async ({ key }) => {
                    setIsMoreLanguagesModalVisible(false);
                    switchLanguage(key);
                }}
                items={filteredLanguages.map(({ key, label }) => ({
                    key,
                    label,
                }))}
            />
        </Modal>
    );
};
