import { currency, currencyEuHelper } from '@fyooga/codebook';
import countries from 'i18n-iso-countries';
import countriesInCzech from 'i18n-iso-countries/langs/cs.json';
import countriesInGermany from 'i18n-iso-countries/langs/de.json';
import countriesInEnglish from 'i18n-iso-countries/langs/en.json';
import countriesInSpain from 'i18n-iso-countries/langs/es.json';
import countriesInFrance from 'i18n-iso-countries/langs/fr.json';
import countriesInHungary from 'i18n-iso-countries/langs/hu.json';
import countriesInItaly from 'i18n-iso-countries/langs/it.json';
import countriesInPoland from 'i18n-iso-countries/langs/pl.json';
import countriesInBrazil from 'i18n-iso-countries/langs/pt.json';
import countriesInSlovakia from 'i18n-iso-countries/langs/sk.json';
import { getParamByParam } from 'iso-country-currency';

import { appLocales } from '../constants';

// Support czech & english & slovak languages.
countries.registerLocale({
    locale: countriesInEnglish.locale,
    countries: countriesInEnglish.countries,
});
countries.registerLocale({
    locale: countriesInCzech.locale,
    countries: countriesInCzech.countries,
});
countries.registerLocale({
    locale: countriesInSlovakia.locale,
    countries: countriesInSlovakia.countries,
});
countries.registerLocale({
    locale: countriesInHungary.locale,
    countries: countriesInHungary.countries,
});
countries.registerLocale({
    locale: countriesInPoland.locale,
    countries: countriesInPoland.countries,
});
countries.registerLocale({
    locale: countriesInSpain.locale,
    countries: countriesInSpain.countries,
});
countries.registerLocale({
    locale: countriesInBrazil.locale,
    countries: countriesInBrazil.countries,
});
countries.registerLocale({
    locale: countriesInFrance.locale,
    countries: countriesInFrance.countries,
});
countries.registerLocale({
    locale: countriesInItaly.locale,
    countries: countriesInItaly.countries,
});
countries.registerLocale({
    locale: countriesInGermany.locale,
    countries: countriesInGermany.countries,
});

const mapLanguageCodeIso = (code: string) => {
    if (code) {
        switch (code) {
            case appLocales.EN: {
                return 'en';
            }
            case appLocales.SK: {
                return 'sk';
            }
            case appLocales.HU: {
                return 'hu';
            }
            case appLocales.PL: {
                return 'pl';
            }
            case appLocales.ES: {
                return 'es';
            }
            case appLocales.PT: {
                return 'pt';
            }
            case appLocales.DE: {
                return 'de';
            }
            case appLocales.IT: {
                return 'it';
            }
            case appLocales.FR: {
                return 'fr';
            }
            default:
                return code;
        }
    }
    return appLocales.CS;
};

export const getCurrencySymbolByCurrency = (currency: string) => {
    try {
        return getParamByParam('currency', currency, 'symbol');
    } catch {
        return '';
    }
};

export const loadCountriesToSelect = (lang: string) => {
    lang = mapLanguageCodeIso(lang);
    const countriesByLang = countries.getNames(lang);
    const filteredCountries = Object.keys(countriesByLang).reduce((obj, key) => {
        return Object.assign(obj, {
            [key]: countriesByLang[key],
        });
    }, {});
    return filteredCountries;
};

export const loadTimezonesToSelect = (timezones: string[]): { [key: string]: string } => {
    const timezoneMap = timezones.reduce((obj, timezone) => {
        return {
            ...obj,
            [timezone]: timezone,
        };
    }, {} as Record<string, string>);

    return timezoneMap;
};

export const getCountryNameByCode = (code: string, lang: string) => {
    lang = mapLanguageCodeIso(lang);
    return countries.getName(code, lang);
};

export const isValidISO3166_1_alpha_2Country = (countryCode: string | null | undefined): boolean => {
    if (countryCode) {
        return countryCode.length === 2 && countries.isValid(countryCode);
    }
    return false;
};

/**
 * flag: INTERNATIONALISATION
 */
export const getTariffCurrencyByLicensePaymentMethodCountry = (countryCode?: string) => {
    const { isCountryUseEuro, isEuMember } = currencyEuHelper;

    if (countryCode === 'CZ') {
        return currency.CZK;
    }
    if (countryCode === 'HU') {
        return currency.HUF;
    }
    if (countryCode === 'PL') {
        return currency.PLN;
    }
    if (countryCode === 'GB') {
        return currency.GBP;
    }
    if (countryCode && isEuMember(countryCode)) {
        return currency.EUR;
    }
    if (countryCode && isCountryUseEuro(countryCode)) {
        return currency.EUR;
    }
    return currency.USD;
};

export const checkIsCzechOrSlovakia = (country?: string) => country === 'CZ' || country === 'SK';
