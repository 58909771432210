import { currencyEuHelper } from '@fyooga/codebook';
import React from 'react';
import styled from 'styled-components';

import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { AddressT } from '../types/address';
import { getCountryNameByCode } from '../util/countries';

const AddressOutputContainer = styled.div<{ marginBottom?: number }>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px` ?? 0};
`;

export const AddressOutput = ({
    address,
    marginBottom,
    withCompanyInfo = false,
}: {
    address: AddressT;
    marginBottom?: number;
    withCompanyInfo?: boolean;
}) => {
    const userAppLocale = useAppLocale();

    const { getVATIdentificationNumberInfo, getTaxIdentificationNumberLocalName } = currencyEuHelper;

    return (
        <AddressOutputContainer marginBottom={marginBottom}>
            <div>{address?.street}</div>
            <div>
                {address?.city}
                {address?.postalCode && `, ${address.postalCode}`}
            </div>
            <div>{getCountryNameByCode(address?.country, userAppLocale)}</div>
            {withCompanyInfo && address.companyIdentificationNumber && address.country && (
                <div>
                    {getTaxIdentificationNumberLocalName(address.country).abbreviation}:{' '}
                    {address.companyIdentificationNumber}
                </div>
            )}
            {withCompanyInfo && address.vatNumber && address.country && (
                <div>
                    {getVATIdentificationNumberInfo(address.country).abbreviation}: {address.vatNumber}
                </div>
            )}
        </AddressOutputContainer>
    );
};
