import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useCheckTariffLimits } from '../hooks/useCheckTariffLimits';
import { OfficeT } from '../types/office';
import { isTariffFree } from '../util/tariff';
import { FreeTariffDoodletip } from './FreeTariffDoodletip';
import { TariffLimitDoodletip } from './TariffLimitDoodletip';

export interface TariffLimitDisabledWrapperProps {
    children: ReactNode;
    office: OfficeT;
    withDoodletip?: boolean;
    doodletipWidth?: 'big' | 'small';
    withLinkToAdminSubscription?: boolean;
    type: 'course' | 'workshop' | 'other';
}

export const FreeTariffWrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
`;

interface DisabledWrapperProps
    extends Pick<TariffLimitDisabledWrapperProps, 'withDoodletip' | 'doodletipWidth' | 'children'> {
    doodletipComponent: ReactNode;
}

export const DisabledWrapper = ({
    withDoodletip,
    doodletipWidth,
    children,
    doodletipComponent,
}: DisabledWrapperProps) => {
    return (
        <>
            {withDoodletip && (
                <>
                    {doodletipWidth === 'big' ? (
                        <FreeTariffWrapperContainer>
                            <div className="page-container-middle">{doodletipComponent}</div>
                        </FreeTariffWrapperContainer>
                    ) : (
                        <>{doodletipComponent}</>
                    )}
                </>
            )}
            <div
                style={{
                    pointerEvents: 'none',
                    fill: '#fff',
                    opacity: 0.5,
                }}
            >
                {children}
            </div>
        </>
    );
};

export const TariffLimitDisabledWrapper = ({
    children,
    office,
    withDoodletip = true,
    doodletipWidth = 'big',
    withLinkToAdminSubscription = true,
    type = 'other',
}: TariffLimitDisabledWrapperProps) => {
    const { domain } = office;
    const { data } = useCheckTariffLimits(office);

    if (!data) {
        return null;
    }

    const isFreeTariff = data.freeTariff;
    const isLiteTariff = data.liteTariff;
    const isProfessionalTariff = data.professionalTariff;

    if (isFreeTariff) {
        const doodletipComponent = (
            <FreeTariffDoodletip domain={domain} withLinkToAdminSubscription={withLinkToAdminSubscription} />
        );

        return (
            <DisabledWrapper
                withDoodletip={withDoodletip}
                doodletipWidth={doodletipWidth}
                doodletipComponent={doodletipComponent}
            >
                {children}
            </DisabledWrapper>
        );
    }

    if (isLiteTariff || isProfessionalTariff) {
        /*
        if (type === 'course') {
            const isCreateNewCourseDisabled = data
                ? data.activeNotCanceledCourses.length >= data.maxAllowedCoursesCount
                : false;

            if (isCreateNewCourseDisabled) {
                const courseDoodletipComponent = (
                    <TariffLimitDoodletip office={office} withLinkToAdminSubscription type="course" />
                );

                return (
                    <DisabledWrapper
                        withDoodletip={withDoodletip}
                        doodletipWidth={doodletipWidth}
                        doodletipComponent={courseDoodletipComponent}
                    >
                        {children}
                    </DisabledWrapper>
                );
            }
        } else if (type === 'workshop') {
            const isCreateNewWorkshopDisabled = data
                ? data.activeNotCanceledWorkshops.length >= data.maxAllowedWorkshopsCount
                : false;

            if (isCreateNewWorkshopDisabled) {
                const workshopDoodletipComponent = (
                    <TariffLimitDoodletip office={office} withLinkToAdminSubscription type="workshop" />
                );

                return (
                    <DisabledWrapper
                        withDoodletip={withDoodletip}
                        doodletipWidth={doodletipWidth}
                        doodletipComponent={workshopDoodletipComponent}
                    >
                        {children}
                    </DisabledWrapper>
                );
            }
        }

         */
    }

    return <>{children}</>;
};
