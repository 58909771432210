import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import { appLocales } from '../../constants';
import { initAppLocale } from '../../util/language';
// CS
import basicFormsTranslationCS from './cs/basicForms.json';
import calendarTranslationCS from './cs/calendar.json';
import commonTranslationCS from './cs/common.json';
import cookieBarTranslationCS from './cs/cookieBar.json';
import doodletipTranslationCS from './cs/doodletip.json';
import errorsTranslationCS from './cs/errors.json';
import formFeedbackTranslationCS from './cs/formFeedback.json';
import lectureTranslationCS from './cs/lecture.json';
import paymentTranslationCS from './cs/payment.json';
import promoVoucherTranslationCS from './cs/promoVoucher.json';
import reservationTranslationCS from './cs/reservation.json';
import settingsTranslationCS from './cs/settings.json';
import statisticsTranslationCS from './cs/statistics.json';
import subscriptionTranslationCS from './cs/subscription.json';
import toastTranslationCS from './cs/toast.json';
// DE (German)
import basicFormsTranslationDE from './de/basicForms.json';
import calendarTranslationDE from './de/calendar.json';
import commonTranslationDE from './de/common.json';
import cookieBarTranslationDE from './de/cookieBar.json';
import doodletipTranslationDE from './de/doodletip.json';
import errorsTranslationDE from './de/errors.json';
import formFeedbackTranslationDE from './de/formFeedback.json';
import lectureTranslationDE from './de/lecture.json';
import paymentTranslationDE from './de/payment.json';
import promoVoucherTranslationDE from './de/promoVoucher.json';
import reservationTranslationDE from './de/reservation.json';
import settingsTranslationDE from './de/settings.json';
import statisticsTranslationDE from './de/statistics.json';
import subscriptionTranslationDE from './de/subscription.json';
import toastTranslationDE from './de/toast.json';
// EN
import basicFormsTranslationEN from './enUS/basicForms.json';
import calendarTranslationEN from './enUS/calendar.json';
import commonTranslationEN from './enUS/common.json';
import cookieBarTranslationEN from './enUS/cookieBar.json';
import doodletipTranslationEN from './enUS/doodletip.json';
import errorsTranslationEN from './enUS/errors.json';
import formFeedbackTranslationEN from './enUS/formFeedback.json';
import lectureTranslationEN from './enUS/lecture.json';
import paymentTranslationEN from './enUS/payment.json';
import promoVoucherTranslationEN from './enUS/promoVoucher.json';
import reservationTranslationEN from './enUS/reservation.json';
import settingsTranslationEN from './enUS/settings.json';
import statisticsTranslationEN from './enUS/statistics.json';
import subscriptionTranslationEN from './enUS/subscription.json';
import toastTranslationEN from './enUS/toast.json';
// ES
import basicFormsTranslationES from './es/basicForms.json';
import calendarTranslationES from './es/calendar.json';
import commonTranslationES from './es/common.json';
import cookieBarTranslationES from './es/cookieBar.json';
import doodletipTranslationES from './es/doodletip.json';
import errorsTranslationES from './es/errors.json';
import formFeedbackTranslationES from './es/formFeedback.json';
import lectureTranslationES from './es/lecture.json';
import paymentTranslationES from './es/payment.json';
import promoVoucherTranslationES from './es/promoVoucher.json';
import reservationTranslationES from './es/reservation.json';
import settingsTranslationES from './es/settings.json';
import statisticsTranslationES from './es/statistics.json';
import subscriptionTranslationES from './es/subscription.json';
import toastTranslationES from './es/toast.json';
//FR
import basicFormsTranslationFR from './fr/basicForms.json';
import calendarTranslationFR from './fr/calendar.json';
import commonTranslationFR from './fr/common.json';
import cookieBarTranslationFR from './fr/cookieBar.json';
import doodletipTranslationFR from './fr/doodletip.json';
import errorsTranslationFR from './fr/errors.json';
import formFeedbackTranslationFR from './fr/formFeedback.json';
import lectureTranslationFR from './fr/lecture.json';
import paymentTranslationFR from './fr/payment.json';
import promoVoucherTranslationFR from './fr/promoVoucher.json';
import reservationTranslationFR from './fr/reservation.json';
import settingsTranslationFR from './fr/settings.json';
import statisticsTranslationFR from './fr/statistics.json';
import subscriptionTranslationFR from './fr/subscription.json';
import toastTranslationFR from './fr/toast.json';
// HU
import basicFormsTranslationHU from './hu/basicForms.json';
import calendarTranslationHU from './hu/calendar.json';
import commonTranslationHU from './hu/common.json';
import cookieBarTranslationHU from './hu/cookieBar.json';
import doodletipTranslationHU from './hu/doodletip.json';
import errorsTranslationHU from './hu/errors.json';
import formFeedbackTranslationHU from './hu/formFeedback.json';
import lectureTranslationHU from './hu/lecture.json';
import paymentTranslationHU from './hu/payment.json';
import promoVoucherTranslationHU from './hu/promoVoucher.json';
import reservationTranslationHU from './hu/reservation.json';
import settingsTranslationHU from './hu/settings.json';
import statisticsTranslationHU from './hu/statistics.json';
import subscriptionTranslationHU from './hu/subscription.json';
import toastTranslationHU from './hu/toast.json';
// IT (Italian)
import basicFormsTranslationIT from './it/basicForms.json';
import calendarTranslationIT from './it/calendar.json';
import commonTranslationIT from './it/common.json';
import cookieBarTranslationIT from './it/cookieBar.json';
import doodletipTranslationIT from './it/doodletip.json';
import errorsTranslationIT from './it/errors.json';
import formFeedbackTranslationIT from './it/formFeedback.json';
import lectureTranslationIT from './it/lecture.json';
import paymentTranslationIT from './it/payment.json';
import promoVoucherTranslationIT from './it/promoVoucher.json';
import reservationTranslationIT from './it/reservation.json';
import settingsTranslationIT from './it/settings.json';
import statisticsTranslationIT from './it/statistics.json';
import subscriptionTranslationIT from './it/subscription.json';
import toastTranslationIT from './it/toast.json';
// PL
import basicFormsTranslationPL from './pl/basicForms.json';
import calendarTranslationPL from './pl/calendar.json';
import commonTranslationPL from './pl/common.json';
import cookieBarTranslationPL from './pl/cookieBar.json';
import doodletipTranslationPL from './pl/doodletip.json';
import errorsTranslationPL from './pl/errors.json';
import formFeedbackTranslationPL from './pl/formFeedback.json';
import lectureTranslationPL from './pl/lecture.json';
import paymentTranslationPL from './pl/payment.json';
// Other languages
import promoVoucherTranslationPL from './pl/promoVoucher.json';
import reservationTranslationPL from './pl/reservation.json';
import settingsTranslationPL from './pl/settings.json';
import statisticsTranslationPL from './pl/statistics.json';
import subscriptionTranslationPL from './pl/subscription.json';
import toastTranslationPL from './pl/toast.json';
// PT
import basicFormsTranslationPT from './pt/basicForms.json';
import calendarTranslationPT from './pt/calendar.json';
import commonTranslationPT from './pt/common.json';
import cookieBarTranslationPT from './pt/cookieBar.json';
import doodletipTranslationPT from './pt/doodletip.json';
import errorsTranslationPT from './pt/errors.json';
import formFeedbackTranslationPT from './pt/formFeedback.json';
import lectureTranslationPT from './pt/lecture.json';
import paymentTranslationPT from './pt/payment.json';
import promoVoucherTranslationPT from './pt/promoVoucher.json';
import reservationTranslationPT from './pt/reservation.json';
import settingsTranslationPT from './pt/settings.json';
import statisticsTranslationPT from './pt/statistics.json';
import subscriptionTranslationPT from './pt/subscription.json';
import toastTranslationPT from './pt/toast.json';
// SK
import basicFormsTranslationSK from './sk/basicForms.json';
import calendarTranslationSK from './sk/calendar.json';
import commonTranslationSK from './sk/common.json';
import cookieBarTranslationSK from './sk/cookieBar.json';
import doodletipTranslationSK from './sk/doodletip.json';
import errorsTranslationSK from './sk/errors.json';
import formFeedbackTranslationSK from './sk/formFeedback.json';
import lectureTranslationSK from './sk/lecture.json';
import paymentTranslationSK from './sk/payment.json';
import promoVoucherTranslationSK from './sk/promoVoucher.json';
import reservationTranslationSK from './sk/reservation.json';
import settingsTranslationSK from './sk/settings.json';
import statisticsTranslationSK from './sk/statistics.json';
import subscriptionTranslationSK from './sk/subscription.json';
import toastTranslationSK from './sk/toast.json';

export const fallbackLanguage = appLocales.EN;

const resources = {
    cs: {
        // Namespaces
        common: commonTranslationCS,
        cookieBarNs: cookieBarTranslationCS,
        doodletipNs: doodletipTranslationCS,
        errorNs: errorsTranslationCS,
        settingsNs: settingsTranslationCS,
        toastNs: toastTranslationCS,
        calendarNs: calendarTranslationCS,
        subscriptionNs: subscriptionTranslationCS,
        paymentNs: paymentTranslationCS,
        formFeedbackNs: formFeedbackTranslationCS,
        formsNs: basicFormsTranslationCS,
        lectureNs: lectureTranslationCS,
        reservationNs: reservationTranslationCS,
        statisticsNs: statisticsTranslationCS,
        promoVoucherNs: promoVoucherTranslationCS,
    },
    de: {
        common: commonTranslationDE,
        cookieBarNs: cookieBarTranslationDE,
        doodletipNs: doodletipTranslationDE,
        errorNs: errorsTranslationDE,
        settingsNs: settingsTranslationDE,
        toastNs: toastTranslationDE,
        calendarNs: calendarTranslationDE,
        subscriptionNs: subscriptionTranslationDE,
        paymentNs: paymentTranslationDE,
        formFeedbackNs: formFeedbackTranslationDE,
        formsNs: basicFormsTranslationDE,
        lectureNs: lectureTranslationDE,
        reservationNs: reservationTranslationDE,
        statisticsNs: statisticsTranslationDE,
        promoVoucherNs: promoVoucherTranslationDE,
    },
    it: {
        common: commonTranslationIT,
        cookieBarNs: cookieBarTranslationIT,
        doodletipNs: doodletipTranslationIT,
        errorNs: errorsTranslationIT,
        settingsNs: settingsTranslationIT,
        toastNs: toastTranslationIT,
        calendarNs: calendarTranslationIT,
        subscriptionNs: subscriptionTranslationIT,
        paymentNs: paymentTranslationIT,
        formFeedbackNs: formFeedbackTranslationIT,
        formsNs: basicFormsTranslationIT,
        lectureNs: lectureTranslationIT,
        reservationNs: reservationTranslationIT,
        statisticsNs: statisticsTranslationIT,
        promoVoucherNs: promoVoucherTranslationIT,
    },
    sk: {
        // Namespaces
        common: commonTranslationSK,
        cookieBarNs: cookieBarTranslationSK,
        doodletipNs: doodletipTranslationSK,
        errorNs: errorsTranslationSK,
        settingsNs: settingsTranslationSK,
        toastNs: toastTranslationSK,
        calendarNs: calendarTranslationSK,
        subscriptionNs: subscriptionTranslationSK,
        paymentNs: paymentTranslationSK,
        formFeedbackNs: formFeedbackTranslationSK,
        formsNs: basicFormsTranslationSK,
        lectureNs: lectureTranslationSK,
        reservationNs: reservationTranslationSK,
        statisticsNs: statisticsTranslationSK,
        promoVoucherNs: promoVoucherTranslationSK,
    },
    enUS: {
        common: commonTranslationEN,
        cookieBarNs: cookieBarTranslationEN,
        doodletipNs: doodletipTranslationEN,
        errorNs: errorsTranslationEN,
        settingsNs: settingsTranslationEN,
        toastNs: toastTranslationEN,
        calendarNs: calendarTranslationEN,
        subscriptionNs: subscriptionTranslationEN,
        paymentNs: paymentTranslationEN,
        formFeedbackNs: formFeedbackTranslationEN,
        formsNs: basicFormsTranslationEN,
        lectureNs: lectureTranslationEN,
        reservationNs: reservationTranslationEN,
        statisticsNs: statisticsTranslationEN,
        promoVoucherNs: promoVoucherTranslationEN,
    },
    hu: {
        common: commonTranslationHU,
        cookieBarNs: cookieBarTranslationHU,
        doodletipNs: doodletipTranslationHU,
        errorNs: errorsTranslationHU,
        settingsNs: settingsTranslationHU,
        toastNs: toastTranslationHU,
        calendarNs: calendarTranslationHU,
        subscriptionNs: subscriptionTranslationHU,
        paymentNs: paymentTranslationHU,
        formFeedbackNs: formFeedbackTranslationHU,
        formsNs: basicFormsTranslationHU,
        lectureNs: lectureTranslationHU,
        reservationNs: reservationTranslationHU,
        statisticsNs: statisticsTranslationHU,
        promoVoucherNs: promoVoucherTranslationHU,
    },
    pl: {
        common: commonTranslationPL,
        cookieBarNs: cookieBarTranslationPL,
        doodletipNs: doodletipTranslationPL,
        errorNs: errorsTranslationPL,
        settingsNs: settingsTranslationPL,
        toastNs: toastTranslationPL,
        calendarNs: calendarTranslationPL,
        subscriptionNs: subscriptionTranslationPL,
        paymentNs: paymentTranslationPL,
        formFeedbackNs: formFeedbackTranslationPL,
        formsNs: basicFormsTranslationPL,
        lectureNs: lectureTranslationPL,
        reservationNs: reservationTranslationPL,
        statisticsNs: statisticsTranslationPL,
        promoVoucherNs: promoVoucherTranslationPL,
    },
    es: {
        common: commonTranslationES,
        cookieBarNs: cookieBarTranslationES,
        doodletipNs: doodletipTranslationES,
        errorNs: errorsTranslationES,
        settingsNs: settingsTranslationES,
        toastNs: toastTranslationES,
        calendarNs: calendarTranslationES,
        subscriptionNs: subscriptionTranslationES,
        paymentNs: paymentTranslationES,
        formFeedbackNs: formFeedbackTranslationES,
        formsNs: basicFormsTranslationES,
        lectureNs: lectureTranslationES,
        reservationNs: reservationTranslationES,
        statisticsNs: statisticsTranslationES,
        promoVoucherNs: promoVoucherTranslationES,
    },
    fr: {
        common: commonTranslationFR,
        cookieBarNs: cookieBarTranslationFR,
        doodletipNs: doodletipTranslationFR,
        errorNs: errorsTranslationFR,
        settingsNs: settingsTranslationFR,
        toastNs: toastTranslationFR,
        calendarNs: calendarTranslationFR,
        subscriptionNs: subscriptionTranslationFR,
        paymentNs: paymentTranslationFR,
        formFeedbackNs: formFeedbackTranslationFR,
        formsNs: basicFormsTranslationFR,
        lectureNs: lectureTranslationFR,
        reservationNs: reservationTranslationFR,
        statisticsNs: statisticsTranslationFR,
        promoVoucherNs: promoVoucherTranslationFR,
    },
    pt: {
        common: commonTranslationPT,
        cookieBarNs: cookieBarTranslationPT,
        doodletipNs: doodletipTranslationPT,
        errorNs: errorsTranslationPT,
        settingsNs: settingsTranslationPT,
        toastNs: toastTranslationPT,
        calendarNs: calendarTranslationPT,
        subscriptionNs: subscriptionTranslationPT,
        paymentNs: paymentTranslationPT,
        formFeedbackNs: formFeedbackTranslationPT,
        formsNs: basicFormsTranslationPT,
        lectureNs: lectureTranslationPT,
        reservationNs: reservationTranslationPT,
        statisticsNs: statisticsTranslationPT,
        promoVoucherNs: promoVoucherTranslationPT,
    },
};

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(intervalPlural)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: fallbackLanguage,
        lng: initAppLocale(fallbackLanguage),
        ns: ['common'],
        defaultNS: 'common',
        debug: process.env.NODE_ENV !== 'production',
        interpolation: { escapeValue: false },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
