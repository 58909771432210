import { appConstants, tariffs } from '@fyooga/codebook';

import { OfficeT } from '../types/office';
import { BookTariff, TariffLimitsDataT } from '../types/tariff';
import {
    isDeprecatedFreelancerTariff,
    isDeprecatedStudioTariff,
    isExpertTariff,
    isLiteTariff,
    isProfessionalTariff,
    isTariffTrial,
    isUltimateTariff,
} from '../util/tariff';

export const FREE_TARIFF_BOOKING_COUNT_LIMIT = appConstants.FREE_TARIFF_BOOKING_COUNT_LIMIT;
export const LITE_TARIFF_BOOKING_COUNT_LIMIT = appConstants.LITE_TARIFF_BOOKING_COUNT_LIMIT;
export const PROFESSIONAL_TARIFF_BOOKING_COUNT_LIMIT = appConstants.PROFESSIONAL_TARIFF_BOOKING_COUNT_LIMIT;
export const EXPERT_TARIFF_BOOKING_COUNT_LIMIT = appConstants.EXPERT_TARIFF_BOOKING_COUNT_LIMIT;

export const LITE_TARIFF_COURSES_COUNT = appConstants.LITE_TARIFF_COURSES_COUNT;
export const LITE_TARIFF_WORKSHOPS_COUNT = appConstants.LITE_TARIFF_WORKSHOPS_COUNT;
export const PROFESSIONAL_TARIFF_COURSES_COUNT = appConstants.PROFESSIONAL_TARIFF_COURSES_COUNT;

export const UNLIMITED_ORDERS_CONSTANT = appConstants.UNLIMITED_ORDERS_CONSTANT;

export const getMaxAllowedCoursesAndWorkshopsCountByTariff = (tariffName: string) => {
    if (isTariffTrial(tariffName)) {
        return {
            courses: UNLIMITED_ORDERS_CONSTANT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }

    if (tariffName === tariffs.FREE.name) {
        return {
            courses: 0,
            workshops: 0,
        };
    }

    // tarify zpetne kompatibilni --- start
    if (isDeprecatedFreelancerTariff(tariffName)) {
        return {
            courses: UNLIMITED_ORDERS_CONSTANT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }

    if (isDeprecatedStudioTariff(tariffName)) {
        return {
            courses: UNLIMITED_ORDERS_CONSTANT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }
    // tarify zpetne kompatibilni --- end

    // new tariffs
    if (isLiteTariff(tariffName)) {
        return {
            courses: LITE_TARIFF_COURSES_COUNT,
            workshops: LITE_TARIFF_WORKSHOPS_COUNT,
        };
    }

    if (isProfessionalTariff(tariffName)) {
        return {
            courses: PROFESSIONAL_TARIFF_COURSES_COUNT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }

    if (isExpertTariff(tariffName)) {
        return {
            courses: UNLIMITED_ORDERS_CONSTANT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }

    if (isUltimateTariff(tariffName)) {
        return {
            courses: UNLIMITED_ORDERS_CONSTANT,
            workshops: UNLIMITED_ORDERS_CONSTANT,
        };
    }

    return {
        courses: UNLIMITED_ORDERS_CONSTANT,
        workshops: UNLIMITED_ORDERS_CONSTANT,
    };
};

export const getMaxAllowedOrdersByTariff = (tariffName: string, isExpertAlaUltimate: boolean) => {
    if (isTariffTrial(tariffName)) {
        return UNLIMITED_ORDERS_CONSTANT;
    }

    if (tariffName === tariffs.FREE.name) {
        return FREE_TARIFF_BOOKING_COUNT_LIMIT;
    }

    // tarify zpetne kompatibilni --- start
    if (isDeprecatedFreelancerTariff(tariffName)) {
        return UNLIMITED_ORDERS_CONSTANT;
    }

    if (isDeprecatedStudioTariff(tariffName)) {
        return UNLIMITED_ORDERS_CONSTANT;
    }
    // tarify zpetne kompatibilni --- end

    // new tariffs
    if (isLiteTariff(tariffName)) {
        return LITE_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isProfessionalTariff(tariffName)) {
        return PROFESSIONAL_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isExpertTariff(tariffName)) {
        // pro ty co si to koupili experta jeste pred pridanim tarifu Ultimate
        if (isExpertAlaUltimate) {
            return UNLIMITED_ORDERS_CONSTANT;
        }

        return EXPERT_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isUltimateTariff(tariffName)) {
        return UNLIMITED_ORDERS_CONSTANT;
    }

    return UNLIMITED_ORDERS_CONSTANT;
};

const isTariffAllowedToCreateNewReservations = (
    bookTariff: BookTariff,
    totalOrdersCount: number,
    isExpertAlaUltimate: boolean,
) => {
    const { name: tariffName } = bookTariff;

    if (isTariffTrial(tariffName)) {
        return true;
    }

    if (tariffName === tariffs.FREE.name) {
        return totalOrdersCount < FREE_TARIFF_BOOKING_COUNT_LIMIT;
    }

    // tarify zpetne kompatibilni --- start
    if (isDeprecatedFreelancerTariff(tariffName)) {
        return true;
    }

    if (isDeprecatedStudioTariff(tariffName)) {
        return true;
    }
    // tarify zpetne kompatibilni --- end

    // new tariffs
    if (isLiteTariff(tariffName)) {
        return totalOrdersCount < LITE_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isProfessionalTariff(tariffName)) {
        return totalOrdersCount < PROFESSIONAL_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isExpertTariff(tariffName)) {
        // pro ty co si to koupili experta jeste pred pridanim tarifu Ultimate
        if (isExpertAlaUltimate) {
            return true;
        }
        return totalOrdersCount < EXPERT_TARIFF_BOOKING_COUNT_LIMIT;
    }

    if (isUltimateTariff(tariffName)) {
        return true;
    }

    return false;
};

export const useCheckTariffLimits = (
    office?: OfficeT,
): {
    data: TariffLimitsDataT | null;
} => {
    if (!office) {
        return {
            data: null,
        };
    }

    const { tariffLimits, isExpertAlaUltimate } = office;
    const {
        organisationTariff,
        bookTariff,
        count_LECTURE,
        count_WORKSHOP,
        count_COURSE,
        activeNotCanceledWorkshops,
        activeNotCanceledCourses,
        activeAllNotCanceledWorkshops,
        activeAllNotCanceledCourses,
        tariffMonthlyLimitValidFrom,
        tariffMonthlyLimitValidTo,
    } = tariffLimits;
    const { name: tariffName } = bookTariff;

    const totalOrdersCount = count_LECTURE + count_WORKSHOP + count_COURSE;

    const { courses: maxAllowedCoursesCount, workshops: maxAllowedWorkshopsCount } =
        getMaxAllowedCoursesAndWorkshopsCountByTariff(tariffName);

    return {
        data: {
            allowToCreateNewReservations: isTariffAllowedToCreateNewReservations(
                bookTariff,
                totalOrdersCount,
                isExpertAlaUltimate,
            ),
            orderCountLecture: count_LECTURE,
            orderCountWorkshop: count_WORKSHOP,
            orderCountCourse: count_COURSE,
            totalOrdersCount,
            activeNotCanceledWorkshops,
            activeNotCanceledCourses,
            freeTariff: tariffName === tariffs.FREE.name,
            liteTariff: isLiteTariff(tariffName),
            professionalTariff: isProfessionalTariff(tariffName),
            maxAllowedOrdersCount: getMaxAllowedOrdersByTariff(tariffName, isExpertAlaUltimate),
            organisationTariff,
            bookTariff,
            maxAllowedCoursesCount,
            maxAllowedWorkshopsCount,
            activeAllNotCanceledWorkshops,
            activeAllNotCanceledCourses,
            tariffMonthlyLimitValidFrom,
            tariffMonthlyLimitValidTo,
        },
    };
};
