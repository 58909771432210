import {
    addDays,
    addMinutes,
    addMonths,
    addWeeks,
    addYears,
    endOfDay,
    format,
    getWeek,
    isBefore,
    parseISO,
    setMilliseconds,
    setSeconds,
    startOfWeek,
} from 'date-fns';
import { cs, de, enUS, es, fr, hu, it, pl, ptBR, sk } from 'date-fns/locale';

import { getDateFromUnixTimestamp, getUnixTimestamp } from '../calendar';
import { getBrowserTimezone } from '../timezone';

export const locales = {
    enUS,
    cs,
    hu,
    pl,
    ptBR,
    sk,
    es,
    de,
    it,
    fr,
};

export const getWeekStartDayByCountryCode = (countryCode: string = 'CZ'): 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined => {
    // Definice zemí, kde týden začíná v neděli
    const sundayStartCountries: string[] = [
        'US', // Spojené státy
        'CA', // Kanada (většina oblastí)
        'IL', // Izrael
        'MX', // Mexiko
        'JP', // Japonsko (v některých případech)
        'AU', // Austrálie (v některých případech)
    ];

    // Pokud je země v seznamu zemí, kde týden začíná v neděli, vrátí 0, jinak 1
    return sundayStartCountries.includes(countryCode.toUpperCase()) ? 0 : 1;
};

export const getShortMonthName = (dateStr: string, localeCode: string) => {
    /*
    CS: 'cs',
    EN: 'enUS',
    SK: 'sk',
    HU: 'hu',
    IT: 'it',
    PL: 'pl',
     */
    // Převedení řetězce na objekt Date
    const date = parseISO(dateStr);

    if (localeCode.startsWith('pt')) {
        localeCode = 'ptBR';
    }

    // Formátování data pro získání zkráceného názvu měsíce
    const shortMonthName = format(date, 'MMM', {
        // @ts-ignore
        locale: locales[localeCode],
    }); // 'MMM' vrátí třípísmenný název měsíce, např. 'Jan', 'Feb', atd.

    return shortMonthName;
};

export const startOfWeekDayByCountry = (date: Date, country = 'CZ') => {
    const weekStartingDay = getWeekStartDayByCountryCode(country);

    return startOfWeek(date, { weekStartsOn: weekStartingDay });
};

export const isCalendarWeekCurrentWeek = (weekStartDay: Date, country = 'CZ') => {
    const weekStartingDay = getWeekStartDayByCountryCode(country);

    return (
        getWeek(new Date(), {
            weekStartsOn: weekStartingDay,
        }) ===
        getWeek(weekStartDay, {
            weekStartsOn: weekStartingDay,
        })
    );
};

export const convertDateToAnotherTimeZone = (date: Date, timezone: string) => {
    const dateString = date.toLocaleString('en-US', {
        timeZone: timezone,
    });
    return new Date(dateString);
};

/**
 * To show warning if browser timezone is different from office timezone
 * @param officeTimezone
 */
export const getOffsetBetweenTimezonesInHours = (officeTimezone: string) => {
    const browserTimezone = getBrowserTimezone();
    const browserNow = new Date();
    const officeTimezoneDate = convertDateToAnotherTimeZone(browserNow, officeTimezone);
    const browserTimezoneDate = convertDateToAnotherTimeZone(browserNow, browserTimezone);
    const differenceInMillis = browserTimezoneDate.getTime() - officeTimezoneDate.getTime();
    return differenceInMillis / 1000 / 60 / 60;
};

export const getOffsetBetweenTwoDatesWithTimezones = (
    dateOne: Date,
    timezoneDateOne: string,
    dateTwo: Date,
    timezoneDateTwo: string,
) => {
    const firstTimezoneDate = convertDateToAnotherTimeZone(dateOne, timezoneDateOne);
    const secondTimezoneDate = convertDateToAnotherTimeZone(dateTwo, timezoneDateTwo);
    const differenceInMillis = secondTimezoneDate.getTime() - firstTimezoneDate.getTime();
    return differenceInMillis / 1000 / 60;
};

/**
 * To calculate UTC date for lecture start
 * @param officeTimezone
 * @param browserNow
 */
export const getOffsetBetweenTimezonesInMinutes = (officeTimezone: string, dateWeAreInterestedIn: Date) => {
    const browserTimezone = getBrowserTimezone();

    return getOffsetBetweenTwoDatesWithTimezones(
        dateWeAreInterestedIn,
        officeTimezone,
        dateWeAreInterestedIn,
        browserTimezone,
    );
};

export const isDifferentTimeZone = (officeTimezone: string): boolean => {
    const browserNow = new Date();
    return getOffsetBetweenTimezonesInMinutes(officeTimezone, browserNow) !== 0;
};

export const isDayInPast = (dayStart: Date) => {
    const dayUnixTimestamp = getUnixTimestamp(dayStart);

    const endOfDayCalendarDay = endOfDay(getDateFromUnixTimestamp(dayUnixTimestamp));
    return isBefore(endOfDayCalendarDay, endOfDay(new Date()));
};

export const isWorkshopInPast = (workshopStart: Date) => {
    const dayUnixTimestamp = getUnixTimestamp(workshopStart);

    return isBefore(getDateFromUnixTimestamp(dayUnixTimestamp), new Date());
};

export const resolveTimeUnitForDate = (date: Date, timeUnit: string, timeValue: number) => {
    switch (timeUnit) {
        case 'day':
            return addDays(date, timeValue);
        case 'week':
            return addWeeks(date, timeValue);
        case 'month':
            return addMonths(date, timeValue);
        case 'year':
            return addYears(date, timeValue);
        default:
            throw new Error(`unknown time unit: ${timeUnit}`);
    }
};

export const isDateStringExpired = (validToDateString: string | null) => {
    let isExpired = false;
    if (validToDateString !== null) {
        isExpired = isBefore(parseISO(validToDateString), new Date());
    }
    return isExpired;
};

export const dateTimeInOfficeTimezone = (dateTime: Date, officeTimezone: string) => {
    const dateTimeInOfficeTimezone = convertDateToAnotherTimeZone(dateTime, officeTimezone);

    // Převod dateTime do časové zóny prohlížeče/serveru, aby server interpretoval čas správně
    // Toto se může zdát zbytečné, pokud server očekává čas v UTC nebo je vždy nastaven na pevnou časovou zónu
    // Offset mezi časovou zónou kanceláře a aktuální časovou zónou prohlížeče/serveru
    const offsetInMinutes = getOffsetBetweenTimezonesInMinutes(officeTimezone, dateTime);

    // Aplikace offsetu na čas v časové zóně kanceláře
    let result = addMinutes(dateTimeInOfficeTimezone, offsetInMinutes);

    // Vynulování sekund a milisekund pro standardizaci výstupu
    result = setSeconds(result, 0);
    result = setMilliseconds(result, 0);

    return result;
};
