import i18next from 'i18next';

import { APPLICATION_SUBDOMAIN } from '../../constants';
import {
    clearEmployeeCurrentHandledOrganisationOffice,
    clearLocalState,
    setAppLocale,
    setUserId,
    setUserIsLoggedIn,
} from '../../lib/apollo/cache';
import { logInUser, logoutUser } from '../axios/api';

export const makeUserLogin = async (
    email,
    password,
    rememberMe = false,
    domainToRedirect,
    recaptchaActionName = 'SKIP_ME_LOGIN',
    recaptchaToken = '',
) => {
    const loggedInUserData = await logInUser(email, password, rememberMe, recaptchaToken, recaptchaActionName);
    const { userId, userConfig } = loggedInUserData;
    const { lang } = userConfig;
    setUserIsLoggedIn(true);
    setUserId(userId);
    if (lang) {
        await i18next.changeLanguage(lang);
        setAppLocale(lang);
    }
    return domainToRedirect;
};

export const makeUserLogout = async client => {
    await logoutUser();
    clearLocalState();
    await client.clearStore();
};
